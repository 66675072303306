import React from 'react';
import './LandingPage.css';

const cardData = [
  { name: 'American Express Gold', image: '/static/images/amex-gold.png', extraEarnings: 100 },
  { name: 'Chase Sapphire Preferred', image: 'static/images/chase-sapphire-preferred.png', extraEarnings: 50 },
  { name: 'Capital One Venture X', image: '/static/images/cap-one-venturex.png', extraEarnings: 150 },
  { name: 'Citi Double Cash', image: 'static/images/citi-double-cash.png', extraEarnings: 30 },
  { name: 'Bilt Rewards', image: 'static/images/bilt-rewards.png', extraEarnings: 10 },
  { name: 'American Express Platinum', image: '/static/images/amex-platinum.png', extraEarnings: 100 },
  { name: 'Chase Ink Business Preferred', image: '/static/images/chase-ink-business-preferred.png', extraEarnings: 120 },
  { name: 'Chase Sapphire Reserve', image: '/static/images/chase-sapphire-reserve.png', extraEarnings: 60 },
  { name: 'Chase Freedom Flex', image: '/static/images/chase-freedom-flex.png', extraEarnings: 25 },
  { name: 'Chase Ink Business Cash', image: '/static/images/chase-ink-business-cash.png', extraEarnings: 120 },
  { name: 'Chase Ink Business Unlimited', image: '/static/images/chase-ink-business-unlimited.png', extraEarnings: 120 },
  { name: 'American Express Blue Cash Preferred', image: '/static/images/amex-blue-cash-preferred.png', extraEarnings: 65 },
  { name: 'American Express Green Card', image: '/static/images/amex-green-card.png', extraEarnings: 100 },
  { name: 'American Express Blue Business Plus', image: '/static/images/amex-blue-business-plus.png', extraEarnings: 40 },
  { name: 'Chase United Explorer', image: '/static/images/chase-united-explorer.png', extraEarnings: 50 },
  { name: 'American Express Delta SkyMiles Gold', image: '/static/images/amex-delta-gold.png', extraEarnings: 35 },
  { name: 'Chase Southwest Rapid Rewards Priority', image: '/static/images/chase-southwest-priority.png', extraEarnings: 100 },
  { name: 'American Express Hilton Honors Surpass', image: '/static/images/amex-hilton-surpass.png', extraEarnings: 50 },
  { name: 'Chase Freedom Unlimited', image: '/static/images/chase-freedom-unlimited.png', extraEarnings: 25 },
  { name: 'American Express EveryDay Preferred', image: '/static/images/amex-everyday-preferred.png', extraEarnings: 80 },
  { name: 'Chase Marriott Bonvoy Boundless', image: '/static/images/chase-marriott-boundless.png', extraEarnings: 100 },
  { name: 'American Express Marriott Bonvoy Brilliant', image: '/static/images/amex-marriott-brilliant.png', extraEarnings: 80 },
  { name: 'Chase World of Hyatt', image: '/static/images/chase-world-of-hyatt.png', extraEarnings: 100 },
  { name: 'American Express Delta SkyMiles Platinum', image: '/static/images/amex-delta-platinum.png', extraEarnings: 35 }
];

const tickerData = [
  { logo: '/static/images/amex-logo.png', name: 'American Express Gold Card', bid: 90, ask: 110 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Sapphire Preferred', bid: 45, ask: 55 },
  { logo: '/static/images/capital-one-logo.png', name: 'Capital One Venture X', bid: 135, ask: 165 },
  { logo: '/static/images/citi-logo.png', name: 'Citi Double Cash', bid: 25, ask: 35 },
  { logo: '/static/images/bilt-logo.png', name: 'Bilt Rewards', bid: 8, ask: 12 },
  { logo: '/static/images/amex-logo.png', name: 'American Express Platinum', bid: 90, ask: 110 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Ink Business Preferred', bid: 110, ask: 130 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Sapphire Reserve', bid: 55, ask: 65 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Freedom Flex', bid: 22, ask: 28 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Ink Business Cash', bid: 110, ask: 130 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Ink Business Unlimited', bid: 110, ask: 130 },
  { logo: '/static/images/amex-logo.png', name: 'American Express Blue Cash Preferred', bid: 60, ask: 70 },
  { logo: '/static/images/amex-logo.png', name: 'American Express Green Card', bid: 90, ask: 110 },
  { logo: '/static/images/amex-logo.png', name: 'American Express Blue Business Plus', bid: 35, ask: 45 },
  { logo: '/static/images/chase-logo.png', name: 'Chase United Explorer', bid: 45, ask: 55 },
  { logo: '/static/images/amex-logo.png', name: 'American Express Delta SkyMiles Gold', bid: 32, ask: 38 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Southwest Rapid Rewards Priority', bid: 90, ask: 110 },
  { logo: '/static/images/amex-logo.png', name: 'American Express Hilton Honors Surpass', bid: 45, ask: 55 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Freedom Unlimited', bid: 22, ask: 28 },
  { logo: '/static/images/amex-logo.png', name: 'American Express EveryDay Preferred', bid: 75, ask: 85 },
  { logo: '/static/images/chase-logo.png', name: 'Chase Marriott Bonvoy Boundless', bid: 90, ask: 110 },
  { logo: '/static/images/amex-logo.png', name: 'American Express Marriott Bonvoy Brilliant', bid: 75, ask: 85 },
  { logo: '/static/images/chase-logo.png', name: 'Chase World of Hyatt', bid: 90, ask: 110 },
  { logo: '/static/images/amex-logo.png', name: 'American Express Delta SkyMiles Platinum', bid: 32, ask: 38 }
];

const LandingPage = () => {
  return (
    <div className="text-white bg-black min-h-screen">
      {/* Header */}
      <header className="bg-black py-4 sticky top-0 z-50">
        <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-bold text-white mb-2 sm:mb-0">Refract</h1>
          <nav className="flex flex-wrap items-center justify-center space-x-4 sm:space-x-8">
            <a href="/" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">Home</a>
            <a href="/guide" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">How It Works</a>
            <a href="mailto:refracttechnologies@gmail.com" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">Contact</a>
            <a href="/login" className="inline-block bg-white text-black font-bold text-xs sm:text-sm px-4 sm:px-8 py-2 sm:py-3 rounded-lg shadow-md get-started hover:shadow-xl">
              Login
            </a>
          </nav>
        </div>
      </header>
      {/* Hero Section */}
      <section className="text-center py-8 sm:py-16"> {/* Adjusted py-12 to py-8 for even less space */}
        <div className="max-w-5xl mx-auto px-2 sm:px-4 lg:px-6">
          <h2 className="text-3xl sm:text-5xl font-extrabold mb-4">Maximize Your Credit Card Rewards</h2>
          <p className="text-md sm:text-lg text-gray-400 mb-8">Earn more points as a seller. Get paid more to sign up as a buyer.</p>
          <a href="/beta" className="inline-block bg-white text-black font-bold text-xs sm:text-sm px-4 sm:px-8 py-2 sm:py-3 rounded-lg shadow-md get-started hover:shadow-xl">
            Sign Up for Beta
          </a>
        </div>

        {/* Card Carousel */}
        <div className="carousel-container mt-8 sm:mt-16"> {/* Reduced space between carousel and start earning now */}
          <div className="carousel-content">
            {cardData.concat(cardData).map((card, index) => ( // Duplicate the array for seamless looping
              <div key={index} className="card-item hover-effect">
                <img src={card.image} alt={card.name} className="card-img" />
                <p className="card-name">{card.name}</p>
                <p className="card-extra">Earn ${card.extraEarnings} more</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Ticker */}
      <div className="ticker-wrapper">
        <div className="ticker-container">
          {tickerData.concat(tickerData).map((item, index) => ( // Duplicate for seamless looping
            <div key={index} className="ticker-item">
              <div className="flex items-center mb-1">
                <img src={item.logo} alt={`${item.name} Logo`} className="ticker-logo" />
                <span className="ticker-name">{item.name}</span>
              </div>
              <div>
                <span className="ticker-bid">BID: ${item.bid}</span> - 
                <span className="ticker-ask">ASK: ${item.ask}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Features Section */}
      {/* ... Your Features Section remains the same ... */}

      {/* Footer */}
      {/* ... Your Footer remains the same ... */}
    </div>
  );
};

export default LandingPage;
