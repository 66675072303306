// src/pages/Login.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Invalid token:', error);
      }
    }
  }, [navigate]);

  useEffect(() => {
    console.log('Google Client ID:', process.env.REACT_APP_GOOGLE_CLIENT_ID);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('http://localhost:8080/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        navigate('/dashboard');
      } else {
        setError(data.message);
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Something went wrong. Please try again.');
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const res = await fetch('http://localhost:8080/api/auth/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tokenId: credentialResponse.credential }),
      });

      const data = await res.json();
      if (res.ok) {
        localStorage.setItem('token', data.token);
        navigate('/dashboard');
      } else {
        setError(data.message);
      }
    } catch (err) {
      console.error('Google login error:', err);
      setError('Google login failed. Please try again.');
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google login failed:', error);
    setError('Google login failed. Please try again.');
  };

  return (
    <div className="bg-black min-h-screen flex flex-col">
      <header className="bg-black py-4 sticky top-0 z-50">
        <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-bold text-white mb-2 sm:mb-0">Refract</h1>
          <nav className="flex flex-wrap items-center justify-center space-x-4 sm:space-x-8">
            <a href="/" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">Home</a>
            <a href="/guide" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">How It Works</a>
            <a href="mailto:refracttechnologies@gmail.com" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">Contact</a>
            <a href="/login" className="inline-block bg-white text-black font-bold text-xs sm:text-sm px-4 sm:px-8 py-2 sm:py-3 rounded-lg shadow-md get-started hover:shadow-xl">
              Login
            </a>
          </nav>
        </div>
      </header>
      <div className="flex-grow flex items-center justify-center">
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-3xl font-bold text-white text-center mb-6">Login</h2>
          {error && <div className="text-red-500 text-center mb-4">{error}</div>}
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-600"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-300">Password</label>
              <input
                type="password"
                id="password"
                className="mt-1 block w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-600"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="text-right">
              <a href="#" className="text-sm text-gray-500 hover:text-gray-400">Forgot Password?</a>
            </div>
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-700"
            >
              Login
            </button>
          </form>
          <div className="mt-6 text-center">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleFailure}
              redirectUri="http://localhost:3000/api/auth/google/callback"
            />
          </div>
          <div className="mt-6 text-center text-gray-400">
            <p>Don't have an account? <a href="/beta" className="text-indigo-400 hover:underline">Sign up</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
