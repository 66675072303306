import React from 'react';
import { FaUserTie, FaUser, FaGift, FaChartLine } from 'react-icons/fa';

const Guide = () => {
  return (
    <div className="bg-black text-white min-h-screen">
      {/* Header */}
      <header className="bg-black py-4 sticky top-0 z-50">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-bold">Refract</h1>
          <nav className="flex flex-wrap items-center justify-center space-x-4 sm:space-x-8">
            <a href="/" className="text-sm sm:text-lg text-gray-400 hover:text-white transition">Home</a>
            <a href="/guide" className="text-sm sm:text-lg text-gray-400 hover:text-white transition">How It Works</a>
            <a href="mailto:refracttechnologies@gmail.com" className="text-sm sm:text-lg text-gray-400 hover:text-white transition">Contact</a>
            <a href="/login" className="inline-block bg-white text-black font-bold text-xs sm:text-sm px-4 sm:px-8 py-2 sm:py-3 rounded-lg shadow-md hover:shadow-xl">
              Login
            </a>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="py-8 sm:py-16">
        <section className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl sm:text-5xl font-extrabold mb-4">What is Refract?</h2>
          <p className="text-md sm:text-lg text-gray-400 mb-8">
            Refract is a marketplace platform that maximizes your credit card rewards. Whether you're a seller or a buyer, Refract helps you earn more points and get paid extra for your card applications.
          </p>

        </section>

        <section id="how-it-works" className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center mt-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
              <FaUserTie className="text-4xl text-white mb-4" />
              <h3 className="text-xl font-bold mb-2">For Sellers</h3>
              <p className="text-gray-400">
                As a seller, you can list your referral links on Refract and set a price for each link. Buyers can then browse and bid on the links they're interested in, ensuring you get the best deal for your referrals.
              </p>
            </div>
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
              <FaUser className="text-4xl text-white mb-4" />
              <h3 className="text-xl font-bold mb-2">For Buyers</h3>
              <p className="text-gray-400">
                As a buyer, you can browse through available referral links and place bids on the ones you're interested in. Sellers can then accept or reject your bids, ensuring you get the best deal for your sign-ups.
              </p>
            </div>
          </div>
        </section>

        <section className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center mt-16">
          <h3 className="text-2xl font-bold mb-4">Why Choose Refract?</h3>
          <p className="text-md sm:text-lg text-gray-400 mb-8">
            Refract offers a streamlined process that saves you time and effort. Enjoy personalized offers and maximize your rewards with minimal hassle.
          </p>
          <div className="text-center">
            <ul className="list-disc list-inside text-left text-gray-400 inline-block text-left">
              <li>Access to exclusive referral links and offers.</li>
              <li>Competitive bidding ensures the best deals.</li>
              <li>Quick payments post approval, ensuring fast access to your earnings.</li>
              <li>Track your rewards and growth effortlessly.</li>
              <li>Secure and user-friendly platform.</li>
              <li>Personalized recommendations based on your preferences.</li>
            </ul>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-black py-4">
        <div className="max-w-7xl mx-auto text-center text-gray-400">
          &copy; 2024 Refract. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Guide;
