// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './LandingPage';
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import Register from './Register';
import Guide from './Guide';
import BetaSignup from './BetaSignup';

import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/guide" element={<Guide />} />
            <Route path="/beta" element={<BetaSignup />} />
          </Routes>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
