import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BetaSignup() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        interest: '',
        otherInterest: '',
    });
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const recaptchaRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaValue) {
            toast.error('Please complete the reCAPTCHA');
            return;
        }

        // Send formData, recaptchaValue, and action name to the backend
        try {
            const response = await fetch('https://api.refract.cx/api/beta-signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, recaptchaValue, action: 'beta-signup' }),
            });
            if (response.ok) {
                toast.success('Signup successful! Check your email for access in the coming weeks.');
                setFormData({
                    name: '',
                    email: '',
                    interest: '',
                    otherInterest: '',
                });
                setRecaptchaValue(null);

                // Reset reCAPTCHA
                if (recaptchaRef.current) {
                    recaptchaRef.current.reset();
                }
            } else {
                toast.error('Signup failed.');
                if (recaptchaRef.current) {
                    recaptchaRef.current.reset(); // Reset on failure as well
                }
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred.');

            // Reset reCAPTCHA on error
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
        }
    };

    return (
        <div className="bg-black min-h-screen flex flex-col">
            <header className="bg-black py-4 sticky top-0 z-50">
                <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center px-4 sm:px-6 lg:px-8">
                    <h1 className="text-2xl font-bold text-white mb-2 sm:mb-0">Refract</h1>
                    <nav className="flex flex-wrap items-center justify-center space-x-4 sm:space-x-8">
                        <a href="/" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">Home</a>
                        <a href="#" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">How It Works</a>
                        <a href="mailto:refracttechnologies@gmail.com" className="text-sm sm:text-lg text-gray-400 hover:text-white transition nav-link">Contact</a>
                        <a href="/login" className="inline-block bg-white text-black font-bold text-xs sm:text-sm px-4 sm:px-8 py-2 sm:py-3 rounded-lg shadow-md get-started hover:shadow-xl">
                            Login
                        </a>
                    </nav>
                </div>
            </header>
            <div className="flex-grow flex items-center justify-center">
                <form onSubmit={handleSubmit} className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full">
                    <h2 className="text-3xl font-semibold text-white text-center mb-6">Join The Beta</h2>
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="w-full px-4 py-2 mb-4 bg-gray-800 border border-gray-700 rounded-md text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="w-full px-4 py-2 mb-4 bg-gray-800 border border-gray-700 rounded-md text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <div className="flex flex-col items-center mb-4">
                        <label className="block text-white mb-2">What interests you the most about Refract?</label>
                        <select
                            name="interest"
                            value={formData.interest}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                            <option value="" disabled>Select an option</option>
                            <option value="earn_rewards">Being able to earn more credit card rewards</option>
                            <option value="paid_extra">Getting paid extra when signing up for credit cards</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    {formData.interest === 'other' && (
                        <input
                            type="text"
                            name="otherInterest"
                            placeholder="Please specify"
                            value={formData.otherInterest}
                            onChange={handleChange}
                            className="w-full px-4 py-2 mb-4 bg-gray-800 border border-gray-700 rounded-md text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    )}
                    <div className="flex justify-center mb-6">
                        <ReCAPTCHA
                            sitekey="6LeMioQqAAAAAMq_NIWrXAedSk1hFD-9PMx31YAM"
                            onChange={handleRecaptchaChange}
                            ref={recaptchaRef}
                        />
                    </div>
                    <button type="submit" className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-700 transition duration-300">
                        Sign Up Now
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}

export default BetaSignup;
